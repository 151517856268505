import React from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CreateProductSchema } from '@app/models/products';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { IFormValues, ProductForm } from '@components/products';
import { useCreateProductFromCatalogQuery } from '@pages/Catalogs/hooks';

import { useGetCatalogQuery } from '../../../../hooks';

export const AddProduct: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const validationT = useTranslation(NSKeys.validation);
  const nomenclatureAddT = useTranslation(NSKeys.nomenclatureAdd);
  const [form] = Form.useForm<CreateProductSchema>();
  const { data: catalog, isLoading: isCatalogLoading } = useGetCatalogQuery(params.id || '');

  const {
    mutate: createProduct,
    isLoading: createIsLoading,
    status: createStatus,
    failureReason: createFailureReason,
  } = useCreateProductFromCatalogQuery();

  const goBack = () => navigate(`/dashboard/catalogs/${params.id}`);

  const onFinish = async (formValues: IFormValues): Promise<void> => {
    const image_uids = formValues.image_uids?.map((el: any) => (el?.url ? el.uid : el?.response?.uid));

    createProduct({ data: { ...formValues, image_uids }, catalog_uid: params.id || '' });
  };

  React.useEffect(() => {
    if (createStatus === 'success') {
      goBack();
    } else if (createFailureReason && form) {
      const formValues = form.getFieldsValue();
      const errors = getFormErrorsFromResponse(formValues, createFailureReason, validationT.t);
      if (errors.length) {
        form.setFields(errors);
      }
    }
  }, [createStatus, createFailureReason]);

  return (
    <Layout>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':catalogId', value: catalog?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle title={nomenclatureAddT.t('title')} loading={isCatalogLoading} />
          <Space size="middle">
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
            <Button
              disabled={createIsLoading}
              loading={createIsLoading}
              type="primary"
              onClick={() => form.submit()}
              size="middle">
              {nomenclatureAddT.t('titleSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ padding: 24 }}>
        <ProductForm form={form} onFinish={onFinish} submitting={createIsLoading} />
      </Layout.Content>
    </Layout>
  );
};
