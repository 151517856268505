import React, { FC, useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { theme as antTheme, Button, Flex, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { OrderStructure } from './components/OrderStructure';
import { ProcessSettings } from './components/ProcessSettings';

interface SettingsPopoverProps {
  test?: string;
}

export enum DRAWER_TYPE {
  PROCESS_SETTINGS = 'processSettings',
  ORDER_STRUCTURE = 'orderStructure',
}

export const SettingsPopover: FC<SettingsPopoverProps> = (props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerType, setDrawerType] = React.useState<DRAWER_TYPE>(DRAWER_TYPE.PROCESS_SETTINGS);
  const ordersT = useTranslation(NSKeys.orders);
  const [isOpen, setOpen] = useState(false);
  const { token } = antTheme.useToken();

  const handleClick = (type: DRAWER_TYPE) => {
    setOpen(false);
    setDrawerOpen(true);
    setDrawerType(type);
  };

  const handleClose = () => setDrawerOpen(false);

  return (
    <>
      <Popover
        arrow={false}
        trigger="click"
        open={isOpen}
        onOpenChange={setOpen}
        destroyTooltipOnHide
        placement="bottomRight"
        overlayInnerStyle={{ padding: '16px 8px' }}
        content={
          <Flex vertical={true} align="start">
            <Button type="text" onClick={() => handleClick(DRAWER_TYPE.PROCESS_SETTINGS)}>
              {ordersT.t('processSettings')}
            </Button>
            <Button type="text" onClick={() => handleClick(DRAWER_TYPE.ORDER_STRUCTURE)}>
              {ordersT.t('orderStructure')}
            </Button>
          </Flex>
        }>
        <Button icon={<SettingOutlined style={{ color: token.colorPrimary }} />} onClick={() => setOpen(true)} />
      </Popover>

      {drawerType === DRAWER_TYPE.PROCESS_SETTINGS && <ProcessSettings open={drawerOpen} onClose={handleClose} />}
      {drawerType === DRAWER_TYPE.ORDER_STRUCTURE && <OrderStructure open={drawerOpen} onClose={handleClose} />}
    </>
  );
};
