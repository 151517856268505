import React from 'react';

import { CheckOutlined, CloseOutlined, EditOutlined, RetweetOutlined } from '@ant-design/icons';

import { Button, Flex, Layout, List, Row, Space, Spin, Steps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ActionKind, ApprovalStepSchema, ArtifactItemSchema, OrderItemSchema, OrderTotal } from '@app/models/orders';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { ListItem } from '@components/ListItem';
import { Paper } from '@components/Paper';

import { getDisplayValueKey } from '../../../../data/getDisplayValueKey';

import { useApplyActionQuery, useGetOrderQuery, useGetProcessQuery } from '../../../../hooks';

import { ChangeStatus } from '../../components/ChangeStatus';

import { HistoryTable } from './components/HistoryTable';
import { ItemsTable } from './components/ItemsTable';
import { StatusChangeModal } from './components/StatusChangeModal';

export const Single: React.FC = () => {
  const navigate = useNavigate();
  const orderT = useTranslation(NSKeys.order);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ orderId: string; processId: string }>();
  const { data: orderData, isLoading: orderIsLoading } = useGetOrderQuery(params?.orderId || '');
  const { data: processData, isLoading: isProcessLoading } = useGetProcessQuery(params?.processId || '');
  const { mutate: applyAction, status: applyStatus, isLoading: isApplyingAction } = useApplyActionQuery();

  const isLoading = orderIsLoading || isProcessLoading;

  const goBack = () => navigate(`/dashboard/processes/${params.processId}/orders`);

  const renderArtifact = (artifact: ArtifactItemSchema) => {
    const artifacts = orderData?.artifacts as any;

    if (artifact.data.type === 'approval_route' && artifacts) {
      const artifactData = artifacts?.[artifact.data.uid];
      const steps = artifactData?.steps as ApprovalStepSchema[];

      if (steps?.length) {
        return (
          <Paper>
            <Typography.Title level={3}>{orderT.t('blockSteps.title')}</Typography.Title>
            <Steps
              labelPlacement="vertical"
              size="small"
              style={{ margin: '20px 0' }}
              items={steps.map((step, i) => ({
                title: orderT.t('blockSteps.steps', { step: i + 1 }),
                status: step?.is_current ? 'process' : step?.is_passed ? 'finish' : 'wait',
                description: step.user_group.name,
              }))}
            />
          </Paper>
        );
      }
    }

    if (artifact.data.type === 'order_items' && artifacts) {
      const artifactData = artifacts?.[artifact.data.uid];
      const items = artifactData?.items as OrderItemSchema[];
      const total = artifactData?.total as OrderTotal;
      const total_items = artifactData?.total_items as number;

      return (
        <Paper>
          <ItemsTable data={items || []} total_items={total_items} total={total} />
        </Paper>
      );
    }
  };

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':ordersId', value: `${orderT.t('breadCrumbPrefix')} ${orderData?.num}` }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <Flex gap={24}>
            <HeaderTitle
              loading={isLoading}
              title={`${orderData?.num || params.orderId}`}
              onClick={goBack}></HeaderTitle>
            {orderData && params.orderId && (
              <Space wrap>
                {orderData.$actions.some((el) => el === ActionKind.ApproveOrder) && (
                  <StatusChangeModal
                    isLoading={isApplyingAction}
                    onSubmit={(data) =>
                      applyAction({
                        order_uid: params.orderId || '',
                        kind: ActionKind.ApproveOrder,
                        data: { payload: data },
                      })
                    }
                    status={applyStatus}
                    version={orderData.version}
                    buttonContent={commonT.t('buttonApprove')}
                    buttonIcon={<CheckOutlined />}
                  />
                )}

                {orderData.$actions.some((el) => el === ActionKind.CancelOrder) && (
                  <StatusChangeModal
                    isLoading={isApplyingAction}
                    onSubmit={(data) =>
                      applyAction({
                        order_uid: params.orderId || '',
                        kind: ActionKind.CancelOrder,
                        data: { payload: data },
                      })
                    }
                    status={applyStatus}
                    version={orderData.version}
                    buttonContent={commonT.t('buttonCancel')}
                    buttonIcon={<CloseOutlined />}
                  />
                )}

                {orderData.$actions.some((el) => el === ActionKind.MarkAsNeedsWork) && (
                  <StatusChangeModal
                    isLoading={isApplyingAction}
                    onSubmit={(data) =>
                      applyAction({
                        order_uid: params.orderId || '',
                        kind: ActionKind.MarkAsNeedsWork,
                        data: { payload: data },
                      })
                    }
                    status={applyStatus}
                    version={orderData.version}
                    buttonContent={orderT.t('buttonMarkAsNeedWork')}
                    buttonIcon={<RetweetOutlined />}
                  />
                )}

                <ChangeStatus
                  statusUid={orderData?.current_status_uid}
                  orderUid={params.orderId}
                  available_statuses_uids={orderData?.$available_statuses?.map((el) => el.uid)}
                />
              </Space>
            )}
          </Flex>
          {orderData?.$operations?.update && orderData?.$permissions?.update && (
            <Button
              icon={<EditOutlined />}
              onClick={() => navigate(`/dashboard/processes/${params.processId}/orders/edit/${params?.orderId}`)}
              size="middle">
              {commonT.t('edit')}
            </Button>
          )}
        </Row>
      </Layout.Header>

      {isLoading ? (
        <Spin style={{ padding: 24 }} />
      ) : (
        <Flex vertical={true} gap={0} style={{ padding: 24 }}>
          {processData?.order_structure?.items?.map((item, i) =>
            item.type === 'field_group' ? (
              <Paper key={i} style={{ padding: '24px 24px' }}>
                <Typography.Title level={3}>{item.data.name}</Typography.Title>
                <List style={{ maxWidth: 600, margin: '20px 0' }} loading={isLoading}>
                  {item.data.fields.map((field, i) => (
                    <ListItem
                      key={i}
                      title={`${field.name}`}
                      description={getDisplayValueKey({
                        type: field.type,
                        alias: field.alias || field.uid,
                        data: orderData?.field_groups?.[item.data.alias || item.data.uid],
                      })}
                    />
                  ))}
                </List>
              </Paper>
            ) : (
              renderArtifact(item)
            )
          )}
          <Paper>{params.orderId && <HistoryTable order_uid={params.orderId} />}</Paper>
        </Flex>
      )}
    </Layout>
  );
};
