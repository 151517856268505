import React, { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { useGroupsQuery } from './hooks/useGroupsQuery';

interface IOption {
  label: string;
  value: string;
}
interface IProps extends SelectProps {
  placeholder: string;
  initialOptions?: IOption[];
}

export const GroupsSelect: React.FC<IProps> = ({ placeholder, initialOptions = [], ...props }) => {
  const { data, hasNextPage, fetchNextPage } = useGroupsQuery({
    size: 10,
  });
  const [options, setOptions] = useState<IOption[]>(initialOptions);

  React.useEffect(() => {
    setOptions(
      data?.pages.flatMap((page) =>
        page.items?.map((el) => ({
          label: `${el.name}`,
          value: el.uid,
        }))
      ) || []
    );
  }, [data]);

  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={placeholder}
      options={options}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.currentTarget;
        if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && hasNextPage) {
          fetchNextPage();
        }
      }}
      {...props}
    />
  );
};
