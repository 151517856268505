import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateCatalogSchema } from '@app/models/products';
import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';

interface IProps {
  data: CreateCatalogSchema;
}

export const useCreateCatalogQuery = () => {
  const client = useQueryClient();
  const catalogsT = useTranslation(NSKeys.catalogs);
  const validationT = useTranslation(NSKeys.validation);

  return useMutation({
    mutationFn: async ({ data }: IProps) => {
      await Api.products.postCatalog(data);
    },
    onError: (err, { data }) => {
      const errors = getFormErrorsFromResponse(data, err, validationT.t);
      message.open({
        type: 'error',
        content: catalogsT.t('errors.createError'),
      });
    },
    onSuccess: async () => {
      await client.invalidateQueries(['getCatalogs']);
      message.open({
        type: 'success',
        content: catalogsT.t('form.toastAddSuccess'),
      });
    },
  });
};
