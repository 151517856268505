import React from 'react';

import { theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ApprovalOrderStatus } from '@app/models/orders';
import { StatusTag } from '@components/StatusTag';

interface IProps {
  status?: string;
}

export const Status: React.FC<IProps> = (props) => {
  const ordersT = useTranslation(NSKeys.orders);
  const { token } = theme.useToken();

  const returnStatus = (color: string, text: string): React.ReactNode => (
    <Tooltip placement="top" title={text}>
      <StatusTag color={color} name={text} />
    </Tooltip>
  );

  const mapStatuses = (name?: string): React.ReactNode => {
    switch (name) {
      case ApprovalOrderStatus.NeedsWork:
        return returnStatus(token.gold4, ordersT.t('statusNeedsWork'));
      case ApprovalOrderStatus.Approved:
        return returnStatus(token.green4, ordersT.t('statusАpproved'));
      case ApprovalOrderStatus.Cancelled:
        return returnStatus(token.red5, ordersT.t('statusCancelled'));
      case ApprovalOrderStatus.NeedsApprove:
        return returnStatus(token.blue3, ordersT.t('statusNeedsApprove'));
      default:
        return returnStatus('#d9d9d9', '-');
    }
  };

  return <React.Fragment>{mapStatuses(props.status)}</React.Fragment>;
};
