import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetOrdersByProcessQuery = (uid?: string) => {
  return useQuery({
    queryKey: ['getOrdersByProcess', uid],
    queryFn: () => Api.orders.getOrdersByProcess(uid || ''),
    enabled: !!uid,
  });
};
