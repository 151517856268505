import { ColumnsType } from 'antd/es/table';

import { OrderHistorySchema } from '@app/models/orders';

import { formatDateAndTimeShort } from '@app/services/date';

import { Status } from '../../../../components/StatusLegacy';

export const BASE_COLUMNS: ColumnsType<OrderHistorySchema> = [
  {
    title: 'historyTable.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    render: (_, data) => formatDateAndTimeShort(data.created_at),
  },
  {
    title: 'historyTable.order_status',
    dataIndex: 'order_status',
    key: 'order_status',
    ellipsis: true,
    render: (_, data) => <Status status={data.order_status} />,
  },
  {
    title: 'historyTable.name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (_, data) => data.user.name,
  },
  {
    title: 'historyTable.comment',
    dataIndex: 'comment',
    key: 'comment',
    ellipsis: true,
    render: (_, data) => {
      if (
        data.info.kind === 'cancelled' ||
        data.info.kind === 'approved' ||
        data.info.kind === 'marked_as_needs_work'
      ) {
        return data.info.comment;
      }
      return '';
    },
  },
  {
    title: 'historyTable.kind',
    dataIndex: 'kind',
    key: 'kind',
    ellipsis: true,
    render: (_, data) => data.info.kind,
  },
];
