import React, { FC, useState } from 'react';

import { Button, Form, Input, message, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { useLocalDispatch } from '@app/services/hooks/useRedux';
import { REFRESH_TOKEN_KEY_NAME, TOKEN_KEY_NAME, TOKEN_KEY_REMEMBER } from '@app/services/httpClient';
import { getAppInitialData } from '@app/store/currentSessionReducer';
import { PhoneInput } from '@components/AntFields';
import { AuthorizationFormLayout } from '@layouts/AuthorizationFormLayout';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { xs: { span: 24 }, sm: { offset: 6, span: 13 } },
};

export const Invite: FC = () => {
  const dispatch = useLocalDispatch();
  const [form] = Form.useForm<IFormValues>();
  const navigate = useNavigate();
  const [checking, setChecking] = useState(false);
  const params = useParams<{ id: string }>();
  const pageT = useTranslation(NSKeys.changePassword);
  const employeesT = useTranslation(NSKeys.employees);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);

  React.useEffect(() => {
    if (params.id) {
      checkInvitation(params.id);
    }
  }, [params.id]);

  const checkInvitation = async (id: string) => {
    setChecking(true);
    await Api.users
      .getInvitations(id)
      .then((res) => {
        form.setFieldValue('email', res.email);
      })
      .catch(() => {
        message.error(commonT.t('error400'));
        navigate('/login');
      })
      .finally(() => setChecking(false));
  };

  const handleSubmit = async ({ second_pwd, ...restValues }: IFormValues): Promise<void> => {
    await Api.users
      .postRegisterInvited({
        invitation_uuid: params.id || '',
        ...restValues,
      })
      .then((res) => {
        localStorage.setItem(REFRESH_TOKEN_KEY_NAME, res?.refresh_token);
        localStorage.setItem(TOKEN_KEY_NAME, res?.access_token);
        localStorage.setItem(TOKEN_KEY_REMEMBER, 'true');
        dispatch(getAppInitialData(() => navigate('/dashboard/profile')));
      })
      .catch((err) => {
        const errors = getFormErrorsFromResponse(restValues, err, validationT.t);
        if (errors.length) {
          form.setFields(errors);
        }
        message.error(commonT.t('error400'));
      });
  };

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  if (checking) {
    return (
      <AuthorizationFormLayout title={pageT.t('title')}>
        <Form.Item {...tailLayout}>
          <Row justify="center" style={{ marginTop: '30px' }}>
            <Spin />
          </Row>
        </Form.Item>
      </AuthorizationFormLayout>
    );
  }

  return (
    <AuthorizationFormLayout title={pageT.t('title')} description={pageT.t('description')}>
      <Form<IFormValues>
        name="invite_form"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        size="large"
        form={form}
        autoComplete="off"
        {...layout}>
        <Form.Item name="email" style={{ opacity: 0, height: 0, position: 'absolute', top: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name="first_name" required label={employeesT.t('form.nameLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.namePlaceholder')} />
        </Form.Item>
        <Form.Item<IFormValues> name="last_name" required label={employeesT.t('form.surnameLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.surnamePlaceholder')} />
        </Form.Item>
        <Form.Item<IFormValues> name="phone" required label={employeesT.t('form.phoneLabel')} rules={[yupSync]}>
          <PhoneInput />
        </Form.Item>
        <Form.Item<IFormValues> name="title" required label={employeesT.t('form.jobtitleLabel')} rules={[yupSync]}>
          <Input placeholder={employeesT.t('form.jobtitlePlaceholder')} />
        </Form.Item>
        <Form.Item required label={pageT.t('passwordNewPlaceholder')} name="password" rules={[yupSync]}>
          <Input.Password placeholder={pageT.t('passwordNewLabel')} />
        </Form.Item>
        <Form.Item
          required
          label={pageT.t('passwordRepeatPlaceholder')}
          name="second_pwd"
          rules={[
            yupSync,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(validationT.t('passwordNotMatch')));
              },
            }),
          ]}>
          <Input.Password placeholder={pageT.t('passwordRepeatLabel')} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Row style={{ width: '100%' }} justify="start">
            <Button type="primary" htmlType="submit">
              {pageT.t('btnContinue')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </AuthorizationFormLayout>
  );
};
