import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { RetrieveOrderSchema } from '@app/models/orders';
import { ORDERS_TABLE_COLUMNS } from '@app/services/appSettings';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { TableEditMenu } from '@components/TableEditMenu';

import { useGetOrdersByProcessQuery } from '../../../../../../hooks';

import { RowsActions } from './components/RowsActions';

import { BASE_COLUMNS_WIP } from './data/constants';

import { Container } from './styles';

// const INITIAL_TABLE_PARAMS = {
//   pagination: {
//     current: 1,
//     pageSize: 10,
//   },
// };

export const TableContainer: FC = () => {
  const navigate = useNavigate();
  const ordersT = useTranslation(NSKeys.orders);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(
    ORDERS_TABLE_COLUMNS,
    BASE_COLUMNS_WIP.map(({ render, ...rest }) => rest)
  );
  const params = useParams<{ processId: string }>();
  const { data: tableData, isLoading } = useGetOrdersByProcessQuery(params.processId);

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...localStorageColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  return (
    <Container style={{ position: 'relative' }}>
      {!!selectedRows.length && <RowsActions selectedRows={selectedRows} onDone={() => setSelectedRows([])} />}
      <TableDnDResizable<RetrieveOrderSchema>
        localStorageName={ORDERS_TABLE_COLUMNS}
        columns={[
          ...localStorageColumns.map((el, index) => ({
            ...el,
            title: ordersT.t(el?.title as any),
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            render: BASE_COLUMNS_WIP.find((baseCol) => baseCol.key === el.key)?.render,
          })),
        ]}
        key="uid"
        rowKey="uid"
        onColumnsChange={setLocalStorageColumns}
        rowClassName="cursorPointer"
        className="tableClassname"
        loading={isLoading}
        dataSource={tableData?.items}
        style={{ marginTop: 24 }}
        scroll={{ x: 'max-content' }}
        pagination={false}
        onRow={(data) => {
          return {
            onClick: (e) => data?.$permissions?.view && navigate(`${data.uid}`),
          };
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows,
          renderCell: (value, record, index, originNode) => (
            <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
          ),
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys as string[]);
          },
        }}
      />
    </Container>
  );
};
