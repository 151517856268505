import React, { FC } from 'react';

import { Button, Form, Layout, message, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import Api from '@app/services/api';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { useEmployee } from '../../providers';

import { UserForm } from './components/UserForm';
import { IFormValues } from './interfaces';

import { SpinnerContainer } from './styles';

export const EditEmployee: FC = () => {
  const params = useParams<{ id: string }>();
  const [submitting, setSubmitting] = React.useState(false);
  const commonT = useTranslation(NSKeys.common);
  const employeesT = useTranslation(NSKeys.employees);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const employee = useEmployee();
  const validationT = useTranslation(NSKeys.validation);

  const goBack = () => navigate('/dashboard/employees');

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    if (employee.data) {
      setSubmitting(true);
      await Api.users
        .putUser(params?.id || '', {
          settings: { ...employee.data.settings },
          is_active: employee.data.is_active,
          ...formValues,
        })
        .then(() => {
          message.success(employeesT.t('form.toastEditSuccess'));
          goBack();
        })
        .catch((err) => {
          const errors = getFormErrorsFromResponse(formValues, err, validationT.t);
          console.log(errors);
          if (errors.length) {
            form.setFields(errors);
          } else {
            message.error(commonT.t('error400'));
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={employeesT.t('titleEdit')} onClick={() => navigate('/dashboard/employees')} />
          <Space size="middle">
            <Button disabled={submitting} type="primary" onClick={() => form.submit()} size="middle">
              {commonT.t('buttonSave')}
            </Button>
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      {!employee.data || employee.loading ? (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      ) : (
        <>
          <UserForm
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              first_name: employee.data?.first_name,
              email: employee.data?.email,
              last_name: employee.data?.last_name,
              phone: employee.data?.phone || '',
              title: employee.data?.title,
              role_pk: employee.data?.role?.pk,
            }}
          />
        </>
      )}
    </>
  );
};
