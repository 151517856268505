import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Select, SelectProps, Space } from 'antd';

import { useUsersQuery } from './hooks/useUsersQuery';

interface IProps extends SelectProps {
  placeholder: string;
  group_uid_ne?: string;
}

export const UsersSelect: React.FC<IProps> = ({ placeholder, group_uid_ne, ...props }) => {
  const { data, hasNextPage, fetchNextPage, isFetching } = useUsersQuery({
    limit: 10,
    filters: {
      group_uid_ne,
    },
  });

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <>
      <Select
        dropdownStyle={{ position: 'fixed' }}
        allowClear
        onPopupScroll={(e: any) => {
          const { target } = e;
          if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
            fetchNextPage();
          }
        }}
        notFoundContent={null}
        filterOption={false}
        {...props}>
        {dataSource?.map((el, i) => (
          <Select.Option key={el.pk} value={el.pk}>
            <Space style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
              <Avatar size={25} src={el?.avatar?.variants?.small?.url || ''} icon={<UserOutlined />} />
              {`${el.first_name} ${el.last_name}`}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
