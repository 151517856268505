import React from 'react';

import { Typography } from 'antd';

import { Container } from './styles';

interface IProps {
  title: string;
  isColumn?: boolean;
  description?: string | number | null;
  descriptionPostfix?: string | number | null | React.ReactNode;
  children?: React.ReactNode;
}

export const ListItem: React.FC<IProps> = (props) => {
  return (
    <Container $isColumn={props.isColumn}>
      <Typography.Text type="secondary" className="listItemTitle">
        {props.title}
      </Typography.Text>
      {props.description !== null && (
        <Typography.Text ellipsis={props.isColumn ? false : { tooltip: props.description }}>
          {props.description || props.description === 0 ? props.description : '—'}{' '}
          {props.description || props.description === 0 ? props.descriptionPostfix : ''}
        </Typography.Text>
      )}
      {props.children}
    </Container>
  );
};
