import { FieldType } from '@app/models/interfaces';
import { formatDate, formatDateAndTime } from '@app/services/date';

export const getDisplayValueKey = ({ type, alias, data }: { type: FieldType; alias: string; data: any }) => {
  if (!data?.[alias] && data?.[alias] !== 0) {
    return '';
  }
  if (type === 'string') {
    return data?.[alias];
  }
  if (type === 'num') {
    return data?.[alias];
  }
  if (type === 'datetime') {
    return formatDateAndTime(data?.[alias]);
  }
  if (type === 'date') {
    return formatDate(data?.[alias]);
  }
  if (type === 'user') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'catalog') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'recipient') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'contact') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'address') {
    return data?.[alias]?.['as_string'] ?? '';
  }
};
