import { FC } from 'react';

import { Button, Flex, Result, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { useGetOrdersByProcessQuery, useGetProcessQuery, useMoveToStatusQuery } from '../../../../../../hooks';

import { KanbanBoard } from './components/KanbanBoard/KanbanBoard';
import { Container } from './styles';

export const BoardContainer: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ processId: string }>();
  const { data: boardData, isLoading: boardIsLoading, isPreviousData } = useGetProcessQuery(params.processId);
  const { data: orders, isLoading: ordersIsLoading } = useGetOrdersByProcessQuery(params.processId);
  const { mutate, mutateAsync, isLoading, failureCount } = useMoveToStatusQuery();

  const handleChangeStatus = (order_uid: string, status_uid: string) => {
    mutate({ order_uid, status_uid });
  };

  if (boardIsLoading || ordersIsLoading) {
    return (
      <Flex style={{ height: '100%', width: '100%' }} justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  if (!boardData) {
    return (
      <Result
        status="500"
        subTitle={commonT.t('error400')}
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            {commonT.t('buttonReload')}
          </Button>
        }
      />
    );
  }

  return (
    <Container>
      <KanbanBoard
        key={failureCount}
        boardData={boardData}
        orders={orders?.items || []}
        isLoading={isLoading}
        onChangeStatus={handleChangeStatus}
      />
      {/* <BoardSectionList boardData={boardData} orders={orders?.items || []} /> */}
    </Container>
  );
};
