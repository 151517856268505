import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { RetrieveOrderSchema } from '@app/models/orders';
import { formatDateAndTimeShort } from '@app/services/date';

import { ChangeStatus } from '../../../../../components/ChangeStatus';

export const BASE_COLUMNS_WIP: ColumnsType<RetrieveOrderSchema> = [
  {
    width: 120,
    title: 'tableColumns.num',
    dataIndex: 'delivery_info.company.num',
    key: 'delivery_info.company.num',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['general']?.['num'],
  },
  {
    width: 200,
    title: 'tableColumns.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    render: (_, data: any) => formatDateAndTimeShort(data?.field_groups?.['general']?.['created_at']),
  },
  {
    width: 200,
    title: 'tableColumns.company_name',
    dataIndex: 'delivery_info.company.name',
    // sorter: true,
    key: 'delivery_info.company.name',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['delivery']?.['recipient']?.['name'],
  },
  {
    width: 200,
    title: 'tableColumns.catalog',
    dataIndex: 'delivery_info.catalog.name',
    // sorter: true,
    key: 'delivery_info.catalog.name',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['general']?.['catalog']?.['name'],
  },
  {
    width: 200,
    title: 'tableColumns.address',
    dataIndex: 'delivery_info.company.address',
    // sorter: true,
    key: 'delivery_info.company.address',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['delivery']?.['address']?.['as_string'],
  },
  {
    width: 200,
    title: 'tableColumns.contact_name',
    dataIndex: 'delivery_info.company.contact_name',
    // sorter: true,
    key: 'delivery_info.company.contact_name',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['delivery']?.['contact']?.['name'],
  },
  {
    width: 200,
    title: 'tableColumns.phone',
    dataIndex: 'delivery_info.company.contact_phone',
    key: 'delivery_info.company.contact_phone',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['delivery']?.['contact']?.['phone'],
  },
  {
    width: 200,
    title: 'tableColumns.author',
    dataIndex: 'author.name',
    key: 'author.name',
    ellipsis: true,
    render: (_, data: any) => data?.field_groups?.['general']?.['author']?.['name'],
  },
  {
    width: 200,
    title: 'tableColumns.status',
    dataIndex: 'status_uid',
    key: 'status_uid',
    align: 'center',
    render: (_, { current_status_uid, uid, $available_statuses }) => (
      <ChangeStatus
        orderUid={uid}
        statusUid={current_status_uid}
        available_statuses_uids={$available_statuses?.map((el) => el.uid)}
      />
    ),
  },
];
