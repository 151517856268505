import React, { Fragment, useState } from 'react';

import { MutationStatus } from '@tanstack/react-query';

import { Button, Form, Input, theme } from 'antd';

import type { ButtonType } from 'antd/es/button/buttonHelpers';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { ModalContainer } from './styles';

interface IFormValues {
  comment: string;
  version: string;
}

interface IProps {
  version: string;
  isLoading: boolean;
  buttonContent: string;
  buttonType?: ButtonType;
  buttonIcon: React.ReactNode;
  status: MutationStatus;
  onSubmit: (data: IFormValues) => void;
}

export const StatusChangeModal: React.FC<IProps> = ({
  status,
  isLoading,
  onSubmit,
  version,
  buttonContent,
  buttonIcon,
  buttonType = 'default',
}) => {
  const [open, setOpen] = useState(false);

  const commonT = useTranslation(NSKeys.common);
  const orderT = useTranslation(NSKeys.order);
  const validationT = useTranslation(NSKeys.validation);
  const { token } = theme.useToken();

  React.useEffect(() => {
    if (status === 'success' || status === 'error') {
      setOpen(false);
      form.resetFields();
    }
  }, [status]);

  const handleSubmit = async (data: IFormValues) => {
    await onSubmit(data);
  };

  const [form] = Form.useForm<IFormValues>();

  return (
    <Fragment>
      <ModalContainer
        title={orderT.t('statusModal.title')}
        centered
        destroyOnClose
        open={open}
        onCancel={() => setOpen(false)}
        colorBorderSecondary={token.colorBorderSecondary}
        cancelText={commonT.t('buttonCancel')}
        okText={commonT.t('buttonOk')}
        okButtonProps={{ disabled: isLoading }}
        onOk={() => form.submit()}>
        <Form<IFormValues>
          name="change_order_status"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{ version: version }}>
          <Form.Item<IFormValues> name="version" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item<IFormValues>
            name="comment"
            rules={[{ required: true, message: validationT.t('required') }]}
            required
            label={orderT.t('statusModal.commentTitle')}>
            <Input.TextArea maxLength={144} rows={2} showCount />
          </Form.Item>
        </Form>
      </ModalContainer>

      <Button icon={buttonIcon} onClick={() => setOpen(true)} size="middle" type={buttonType}>
        {buttonContent}
      </Button>
    </Fragment>
  );
};
