import React from 'react';

import { DatePicker, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { FieldType } from '@app/models/interfaces';
import { CatalogsSelect, ContactsSelect, RecipientsSelect } from '@components/AntFields';

interface IProps {
  type: FieldType;
  form: FormInstance;
  label: string;
  name: string | string[];
  disabled?: boolean;
  required?: boolean;
}

export const RenderFieldByType: React.FC<IProps> = ({ type, label, disabled = true, required = false, name, form }) => {
  const validationT = useTranslation(NSKeys.validation);

  const commonRules = [
    ...(required
      ? [
          {
            required: true,
            message: validationT.t('required'),
          },
        ]
      : []),
  ];

  const getElement = () => {
    if (type === 'string' || type === 'num') {
      return (
        <Form.Item label={label} required={required} name={name} rules={commonRules}>
          <Input placeholder={label} disabled={disabled} />
        </Form.Item>
      );
    }

    if (type === 'date') {
      return (
        <Form.Item
          label={label}
          required={required}
          name={name}
          rules={commonRules}
          getValueFromEvent={(e: any) => e?.format('YYYY-MM-DD')}
          getValueProps={(e: string) => ({
            value: e ? dayjs(e) : '',
          })}>
          <DatePicker style={{ width: '100%' }} disabled={disabled} format="YYYY-MM-DD" />
        </Form.Item>
      );
    }

    if (type === 'datetime') {
      return (
        <Form.Item label={label} required={required} name={name} rules={commonRules}>
          <DatePicker showTime style={{ width: '100%' }} disabled={disabled} />
        </Form.Item>
      );
    }

    if (type === 'user') {
      return (
        <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
          <ContactsSelect
            disabled={disabled}
            showSearch
            placeholder="test"
            onChange={(e, opt) => console.log(e, opt)}
          />
        </Form.Item>
      );
    }

    if (type === 'recipient') {
      return (
        <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
          <RecipientsSelect placeholder={label} showSearch disabled={disabled} />
        </Form.Item>
      );
    }

    if (type === 'contact') {
      return (
        <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
          <ContactsSelect placeholder={label} showSearch disabled={disabled} />
        </Form.Item>
      );
    }

    if (type === 'catalog') {
      return (
        <>
          <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
            <CatalogsSelect
              placeholder={label}
              disabled={disabled}
              onChange={(e, opt: any) => form.setFieldValue([...name, 'name'], opt?.label || '')}
            />
          </Form.Item>

          <Form.Item
            style={{ display: 'none' }}
            label={label}
            required={required}
            name={[...name, 'name']}
            rules={commonRules}>
            <Input />
          </Form.Item>
        </>
      );
    }

    return <div></div>;
  };

  return getElement();
};
